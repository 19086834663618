<template lang="pug">
.button-wrapper(v-if="blok" v-editable="blok" :style="{ justifyContent: isMobile ? 'center' : blok.justify }" :class="blok.button_text && blok.button_text.length > 0 ? 'has-subtext' : ''")
	ClientOnly
		SingaIcon(v-if="blok.icon_position === 'left' && blok.icon_outside" :icon="blok.icon" size="large")
	SingaButton.is-primary.is-regular(
		v-if="!blok.hide"
		:class="[{ thin: blok.thin }, { 'filled-centered': blok.variant }, blok.color]"
		v-editable="blok"
		@click="openUrl(blok.link.cached_url)"
		:outlined="blok.variant === 'outline'"
		:icon-left="blok.icon_position === 'left' && !blok.icon_outside ? blok.icon : ''"
		:icon-right="blok.icon_position === 'right' && !blok.icon_outside ? blok.icon : ''"
		) {{ blok.label }}
	.subtext-wrapper
		span(v-if="blok.button_text && blok.button_text.length > 0" v-editable="blok").subtext {{ blok.button_text }}
	ClientOnly
		SingaIcon(v-if="blok.icon_position === 'right' && blok.icon_outside" :icon="blok.icon" size="large")
</template>
<script setup lang="ts">
// @ts-ignore
const link = useLinks()
const localePath = useLocalePath()
const emit = defineEmits([
	'open'
])

// Add different comment
const route = useRoute()
const { isMobile } = useDevice()
const props = defineProps({
	blok: {
		required: true,
		type: Object
	}
})

const openUrl = async (url: string) => {
	if (props.blok.modal_opener) {
		emit('open')
		return
	}
	if (route.query._storyblok) {
		return
	}
	if (props.blok.link.anchor) {
		return
	}
	if (url.includes('https') || url.includes('http') || url.includes('www') || props.blok.link.linktype === 'story') {
		await navigateTo(url, {
			external: true
		})
	} else if (link) {
		await navigateTo({ path: link.parseStringLink(localePath(url)) })
	} else {
		await navigateTo({ path: localePath(url) })
	}
}
</script>
<style lang="sass" scoped>
.button-wrapper
	display: flex
	flex-direction: row
	align-items: center
	min-height: 24px
	.button
		&.thin
			line-height: 1!important
			padding: calc(0.375em - 1px) 0.75em!important
			font-size: 1rem
			height: 2.25em
		&.centered
			margin: 0 auto
			margin-bottom: $spacing-32
	&.has-subtext
		flex-direction: column
		.button
			margin-bottom: $spacing-8

.subtext
	@include font(basier, regular)
	@include fontSize(s)
	color: $color-grey-60
	display: block

</style>
