export const useLinks = () => {
	const parseStringLink = (link: string) => {
		return link
	}
	const parseLink = (link: any) => {
		return link
	}
	return {
		parseLink,
		parseStringLink
	}
}
